var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-md-3",attrs:{"fab":!_vm.$vuetify.breakpoint.mdAndUp,"small":!_vm.$vuetify.breakpoint.mdAndUp}},'v-btn',attrs,false),on),[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-icon',{attrs:{"left":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v("mdi-alert-octagon")]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v(_vm._s(_vm.$t("contactsupport")))]):_vm._e()],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("contactus"))))])],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-select',{attrs:{"rules":[function (v) { return !!v || _vm.$t('contact.requiredsubject'); }],"items":_vm.subjects,"label":_vm._f("capitalize")(_vm.$t('contact.selectsubject')),"prepend-icon":"mdi-help-circle","single-line":"","required":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$t("contact." + item.text)))]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$t("contact." + item.text)))]}}]),model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_c('v-textarea',{attrs:{"rules":[function (v) { return !!v || _vm.$t('contact.requiredmessage'); }],"label":_vm._f("capitalize")(_vm.$t('contact.yourmessage')),"rows":"6","prepend-icon":"mdi-comment","clearable":"","clear-icon":"mdi-close-circle","required":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("contact.cancel"))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"primary"},on:{"click":function($event){return _vm.sendMessage(_vm.subject.value, _vm.message)}}},[_vm._v(_vm._s(_vm.$t("contact.send")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }