<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mr-md-3"
        v-bind="attrs"
        v-on="on"
        :fab="!$vuetify.breakpoint.mdAndUp"
        :small="!$vuetify.breakpoint.mdAndUp"
        ><v-icon v-if="!$vuetify.breakpoint.mdAndUp" :left="$vuetify.breakpoint.mdAndUp">mdi-alert-octagon</v-icon
        ><span v-if="$vuetify.breakpoint.mdAndUp">{{
          $t("contactsupport")
        }}</span></v-btn
      >
    </template>
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>{{ $t("contactus") | capitalize }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form v-model="valid">
          <v-select
            v-model="subject"
            :rules="[(v) => !!v || $t('contact.requiredsubject')]"
            :items="subjects"
            :label="$t('contact.selectsubject') | capitalize"
            prepend-icon="mdi-help-circle"
            single-line
            required
            return-object
          >
            <template v-slot:selection="{ item }">{{
              $t("contact." + item.text)
            }}</template>
            <template v-slot:item="{ item }">{{
              $t("contact." + item.text)
            }}</template>
          </v-select>
          <v-textarea
            v-model="message"
            :rules="[(v) => !!v || $t('contact.requiredmessage')]"
            :label="$t('contact.yourmessage') | capitalize"
            rows="6"
            prepend-icon="mdi-comment"
            clearable
            clear-icon="mdi-close-circle"
            required
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text @click="dialog = false">
          {{ $t("contact.cancel") }}
        </v-btn>
        <v-btn
          :disabled="!valid"
          @click="sendMessage(subject.value, message)"
          color="primary"
          >{{ $t("contact.send") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    subject: null,
    subjects: [
      { text: "information", value: "INFORMATION" },
      { text: "bug", value: "BUG" },
      // { text: "fraud", value: "FRAUD" },
    ],
    message: "",
    valid: true,
  }),
  methods: {
    sendMessage(subject, message) {
      this.$store.dispatch("contact/sendMessage", {
        subject,
        message,
        alertId: this.$route.params.id,
      });

      this.dialog = false;
    },
  },
};
</script>
